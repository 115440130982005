<template>
  <div class="FormSent">
    <img src="@/assets/images/check.svg" alt="check form" class="FormSent-Img">
    <p class="Text Text--large FormSent-Text" :class="{'font-ComicSansMS': this.$i18n.locale === 'pl' || this.$i18n.locale === 'tr'}">
      {{$t('form-sent.text')}}
    </p>
    <router-link to="/" class="BaseButton FormSent-Link">
          <span class="BaseButton--colored-red BaseButton-Content">
              {{$t('form-sent.button')}}
             <img :src="require(`@/assets/images/red-arrow.svg`)"
                  alt="arrow"
                  class="BaseButton-Img"
             >
          </span>
      <div class="BaseButton-Border"></div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "FormSent"
}
</script>

<style lang="scss">
.FormSent {
  padding: 62px 0;
  text-align: center;

  @media (min-width: $screen-l) {
    padding: 152px 0;
  }

  &-Img {
    width: 96px;
    margin-bottom: 40px;
    @media (min-width: $screen-l) {
      width: auto;
    }
  }

  &-Text {
    margin-bottom: 60px;
    font-family: 'KomikaTitle', sans-serif, monospace;
    font-size: 18px;

    @media (min-width: $screen-l) {
      font-size: 28px;
    }
  }

  &-Link {
    width: 283px;
    height: 55px;

    @media (min-width: $screen-l) {
      width: 260px;
      height: 83px;
    }
  }
}
</style>
