<template>
  <section class="ContactUs">
    <div class="Wrapper">
      <div v-if="!getIsFormSent" class="ContactUs-Content">
        <BaseH2 class="Title Title--h2 Title--dark ContactUs-Title">
          {{ $t('contacts-page.title') }}
        </BaseH2>
        <BaseSubtitle class="ContactUs-Subtitle" :class="{'font-ComicSansMS': this.$i18n.locale === 'pl' || this.$i18n.locale === 'tr'}">
          {{ $t('contacts-page.subtitle') }}
        </BaseSubtitle>
      </div>
      <transition name="fade">
        <FormSent v-if="getIsFormSent"/>
      </transition>
      <Form v-if="!getIsFormSent"/>
    </div>
  </section>
</template>

<script>
import Form from "../components/Form";
import {mapActions, mapGetters, mapState} from "vuex";
import FormSent from "../components/FormSent";

export default {
  name: "ContactUs",
  components: {FormSent, Form},
  computed: {
    ...mapGetters(['getIsFormSent']),
    ...mapState(['isFormSent'])
  },
  methods: {
    ...mapActions(['SET_FORM_SENT']),
  },
  beforeDestroy() {
    if (this.isFormSent) {
      this.SET_FORM_SENT(false)
    }
  }
}
</script>

<style lang="scss">
.ContactUs {
  padding: 103px 0 95px;

  @media (min-width: $screen-s) {
    padding: 140px 0 95px;
  }

  @media (min-width: $screen-m) {
    padding: 140px 0 151px;
  }

  @media (min-width: $screen-l) {
    padding: 235px 0 151px;
  }

  &-Content {
    text-align: center;
  }

  &-Title {
    text-align: center;
  }

  &-Subtitle {
    font-family: 'KomikaTitle', sans-serif, monospace;
    font-size: 18px;
    text-align: center;

    @media (min-width: $screen-l) {
      font-size: 23px;
    }

    @media (min-width: $screen-xl) {
      font-size: 28px;
    }
  }

}

</style>
