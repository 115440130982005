import { render, staticRenderFns } from "./FormSent.vue?vue&type=template&id=0197fa56&"
import script from "./FormSent.vue?vue&type=script&lang=js&"
export * from "./FormSent.vue?vue&type=script&lang=js&"
import style0 from "./FormSent.vue?vue&type=style&index=0&id=0197fa56&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports