<template>
  <form class="Form" novalidate @submit.prevent="onSubmit()">
    <div class="Form-Content">
      <div class="Form-Inputs">
        <div class="Form-InputsTop">
          <BaseInput
              type="text"
              :label="form.name.label"
              v-model="form.name.value"
              class="Form-Input"
              :v="$v.form.name.value"
          />
          <BaseInput
              type="text"
              :label="form.email.label"
              v-model="form.email.value"
              class="Form-Input"
              :v="$v.form.email.value"
          />
        </div>
        <BaseInput
            type="text"
            :label="form.title.label"
            v-model="form.title.value"
            :v="$v.form.title.value"
        />
      </div>
      <BaseTextarea
          :label="form.message.label"
          v-model="form.message.value"
          class="Form-Textarea"
          :v="$v.form.message.value"
      />
    </div>
    <BaseButton
        type="submit"
        :button-red="true"
        :image="'red-arrow.svg'"
        class="Form-Btn"
    >
      {{ $t('form.submit') }}
    </BaseButton>
  </form>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {email, required} from "vuelidate/lib/validators";
import axios from "axios";
import {mapActions} from "vuex";

export default {
  name: "Form",
  mixins: [validationMixin],
  data() {
    return {
      form: {
        name: {
          value: '',
          label: this.$i18n.t('form.name.label')
        },
        email: {
          value: '',
          label: this.$i18n.t('form.email.label')
        },
        title: {
          value: '',
          label: this.$i18n.t('form.title.label'),
        },
        message: {
          value: '',
          label: this.$i18n.t('form.message.label'),
        }
      }
    }
  },
  validations: {
    form: {
      name: {
        value: {
          required
        }
      },
      email: {
        value: {
          required,
          email,
        }
      },
      title: {
        value: {
          required
        }
      },
      message: {
        value: {
          required
        }
      },
    }
  },
  methods: {
    ...mapActions(['SET_FORM_SENT']),
    onSubmit() {
      this.$v.form.$touch();
      if (!this.$v.form.$error) {
        const params = new URLSearchParams();
        params.append('name', this.form.name.value);
        params.append('email', this.form.email.value);
        params.append('title', this.form.title.value);
        params.append('message', this.form.message.value);

        axios.post(
            "/mail.php",
            params,
            {
              headers: {
                'content-type': 'application/x-www-form-urlencoded'
              }
            }
        ).then(() => {
        })
        this.SET_FORM_SENT(true)
        this.form.name.value = ''
        this.form.email.value = ''
        this.form.title.value = ''
        this.form.message.value = ''
        this.$nextTick(() => {
          this.$v.$reset()
        })
      }
    }
  }

}
</script>

<style lang="scss">
.Form {
  width: 100%;
  margin: 0 auto;

  @media (min-width: $screen-xl) {
    width: 1098px;
  }

  &-Content {
    padding: 24px;
    border: 5px solid var(--color-border);
    background-color: var(--color-bg-white);

    @media (min-width: $screen-m) {
      padding: 52px;
    }
  }

  &-Inputs {
    margin-bottom: 39px;
  }

  &-InputsTop {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 25px;

    @media (min-width: $screen-m) {
      flex-direction: row;
      margin-bottom: 40px;
    }
  }

  &-Input {
    width: 100%;
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 0;
    }

    @media (min-width: $screen-m) {
      margin-right: 48px;
      margin-bottom: 0;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &-Btn {
    margin: 35px 0 0;
    width: 100%;
    height: 55px;

    @media (min-width: $screen-m) {
      width: 224px;
      height: 64px;
      margin: 32px 0 0;
      left: 100%;
      transform: translate(-100%, 0);
    }
  }
}
</style>
